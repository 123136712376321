import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// importing layout
import { Main } from "../layouts/main"

// Importing styles
import "./styles/default.scss"

// Importing Bootstrap components
import { Button, Container, Row } from "react-bootstrap"

export default function About() {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "About" }) {
        content
      }
    }
  `)

  // Wordpress data
  const { wpPage } = data

  return (
    <Main>
      <div className="default">
        <div className="intro">
          <Container className="fade-in-slow">
            <StaticImage
              className="image"
              src="../assets/images/intro-logo.png"
              alt="The Elevated Life logo."
              placeholder="tracedSVG"
            />
            <h1 className="shout">About Us</h1>
          </Container>
        </div>
        <Container className="post-content fade-in-slow">
          <div dangerouslySetInnerHTML={{ __html: wpPage.content }} />
        </Container>
      </div>
    </Main>
  )
}
